<template>
  <div class="detailed">
    <slot />
  </div>
</template>

<script>
export default {
  name: "DetailedPageContainer"
}
</script>

<style lang="scss">
.detailed {
  padding-bottom: 144px;

  &__images {
    margin-bottom: 24px;
  }

  &__title {
    margin-bottom: 16px;

    &--small {
      margin-bottom: 8px;
    }
  }

  &__category {
    margin-bottom: 4px;
  }

  &__map {
    margin-top: 24px;
  }

  &__additional-info {
    margin-top: 32px;
  }

  &__more-items {
    &:not(:empty) {
      margin-top: 40px;
    }
  }

  &__agency {
    margin-top: 26px;
  }
}

</style>
