<template>
  <DetailedPageContainer>
    <div class="detailed__card">
      <r-row class="px-4">
        <r-col :cols="{ widest: 4, wide: 4, middle: 6, narrow: 6 }">
          <AgencyAndGuideCard :name="currentItem.title"
                              :image="currentItem.image ? currentItem.image.replace('extra', 'small') : ''"
                              :type="currentItem.type"
          />
        </r-col>
      </r-row>
    </div>

    <r-row class="px-4">
      <r-col :cols="{ widest: 4, wide: 4, middle: 2, narrow: 3 }">
        <div class="zamorano harakiri--text">
          {{ description }}
        </div>
      </r-col>

      <r-col :cols="{ widest: 4, wide: 4, middle: 2, narrow: 3 }">
        <div class="details" v-if="address">
          <r-icon icon="geopoint" fill="rocky"/>
          <span class="zamorano">
            {{ address }}
          </span>
        </div>

        <div class="details" v-if="phone">
          <r-icon icon="telephone" fill="rocky"/>
          <span class="zamorano">
            {{ phone }}
          </span>
        </div>

        <div class="details" v-if="email">
          <r-icon icon="mail" fill="rocky"/>
          <span class="zamorano">
            {{ email }}
          </span>
        </div>

        <div class="details" v-if="website">
          <r-icon icon="link" fill="rocky"/>
          <span class="zamorano">
            {{ website }}
          </span>
        </div>
      </r-col>
    </r-row>
  </DetailedPageContainer>
</template>

<script>
import {mapGetters} from "vuex";
import DetailedPageContainer from "@/components/Containers/DetailedPageContainer";
import AgencyAndGuideCard from "@/components/DetailedViewParts/AgencyAndGuideCard/AgencyAndGuideCard";

export default {
  name: "DetailedAgencyAndGuideView",

  components: {
    AgencyAndGuideCard,
    DetailedPageContainer
  },

  computed: {
    ...mapGetters(['GET_TRAVEL_AGENCIES', 'GET_GUIDES']),

    mixAgenciesAndGuides() {
      return [...this.GET_TRAVEL_AGENCIES, ...this.GET_GUIDES];
    },

    currentItem() {
      return this.mixAgenciesAndGuides.filter(item => item.id == this.$route.params.id)[0];
    },

    address() {
      return this.currentItem?.address;
    },

    phone() {
      return this.currentItem?.phone;
    },

    email() {
      return this.currentItem?.email;
    },

    website() {
      return this.currentItem?.website;
    },

    description() {
      return this.currentItem?.description.replace(/<[^>]*>?/gm, '');
    }
  }
}
</script>

<style lang="scss">
.detailed {

  &__card {
    margin-bottom: 16px;
  }
}
</style>
